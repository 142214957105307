.rangeDatePicker .react-datepicker {
  position: absolute;
  top: 0;
  left: 0;
}

/* datepicker input 창 */
.react-datepicker-wrapper input {
  display: none;
}

.react-datepicker__triangle {
  display: none;
}

/* 달력  */
/* .react-datepicker {
  border: 0.1rem solid #d0d0d0 !important;
  box-shadow: none !important;
  width: 26rem;
  display: flex;
  border: 0.1rem solid #d0d0d0;
  box-shadow: none;
  letter-spacing: -0.03rem;
  position: absolute;
  top: 5rem;
  left: -18rem;
} */

.react-datepicker-popper {
  padding: 1.6rem 1.2rem;
}

.react-datepicker__header {
  background-color: #fff;
  border: none;
  padding: 0;
}


.react-datepicker__week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: 3.2rem;
  margin: 0.4rem 0;
}

/* 오늘 선택 불가  */
/* .react-datepicker__day--today {
  background-color: #fff;
  color: #cccccc;
}

.react-datepicker__day--today:hover {
  background-color: #fff;
  color: #cccccc;
  cursor: default;
} */

.react-datepicker__day--range-start {
  background-color: #ffcd00 !important;
  border-radius: 0.4rem;
  color: #000;
}
.react-datepicker__day--in-range {
  background-color: #fffbe5;
  border-radius: 0.4rem;
  color: #000;
}
.react-datepicker__day--range-end {
  background-color: #ffcd00;
  border-radius: 0.4rem;
  color: #000;
}
.react-datepicker__day--keyboard-selected {
  background-color: #ffcd00;
  border-radius: 0.4rem;
  color: #000;
}
.react-datepicker__day--disabled {
  color: #cccccc;
  background-color: #fff;
}
.react-datepicker__day--disabled:hover {
  color: #cccccc;
  background-color: #fff;
}
.react-datepicker__day:active {
  border: none;
}
/* 범위 선택 도중 호버하고있는 컴포넌트 */
.react-datepicker__day--in-range:hover {
  background-color: #ffcd00;
  color: #000;
}


.react-datepicker__day-names {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}


.react-datepicker__day-name {
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.4rem;
  margin: 0;
  color: #1a468b;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
  /* line-height: 2.4rem; */
  letter-spacing: -0.39px;
}

.react-datepicker__day {
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.4rem;
  margin: 0;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.4rem;
  letter-spacing: -0.042rem;
}

/* 과거 날짜 */
.react-datepicker__day .react-datepicker__day--disabled {
  background-color: #fff;
  color: #cccccc !important;

  > span {
    color: #cecece;
  }
}

.react-datepicker__day--outside-month {
  color: #cccccc;
}

/* select 전 드래그 중 날짜 기간 */
.react-datepicker__day--selecting-range-start.react-datepicker__day--in-selecting-range {
  background-color: #ffcd00;
  border-radius: 0.4rem;
  color: #000;
}

.react-datepicker__day--selecting-range-end.react-datepicker__day--in-selecting-range {
  background-color: #ffcd00;
  border-radius: 0.4rem;
  color: #000;
}
.react-datepicker__day--in-selecting-range {
  background-color: #ffcd00 !important;
  border-radius: 0.4rem;
  color: #000;
}
