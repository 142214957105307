.singleDatePicker .react-datepicker {
  position: absolute;
  top: 0;
  left: 0;
}

.react-datepicker-popper {
  z-index: 888888;
  position: absolute;
  top: 0;
  left: 10rem;
  max-width: 25.8rem;
  padding: 0 1.7rem;
  border: 0.1rem solid #d0d0d0;
  background: #fff;
  border-radius: 0.3rem;
}

.react-datepicker__aria-live {
  display: none;
}
.singleDatePicker .react-datepicker__week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.react-datepicker__day-names {
  display: flex;
}
.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: #fff;
  border: none;
  padding: 0;
  width: 20rem;
}

.react-datepicker__week {
  height: 3.2rem;
}

/* 범위 선택 도중 호버하고있는 컴포넌트 */
.react-datepicker__day:hover {
  background-color: #ffcd00;
  color: #000;
  border-radius: 0.3rem;
  cursor: pointer;
}

.react-datepicker__day--selected {
  background-color: #ffcd00;
  color: #000;
  border-radius: 0.3rem;
}

.react-datepicker__day .react-datepicker__day--today {
  background-color: #ffcd00;
  color: #000;
  border-radius: 0.3rem;
}

.react-datepicker__day--keyboard-selected {
  background-color: #fff;
}

.react-datepicker__day-names {
  max-width: 20rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.react-datepicker__day-name {
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.4rem;
  margin: 0;
  font-family: 'Pretendard', serif;
  color: #1a468b;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: -0.039rem;
}

.react-datepicker__day {
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.4rem;
  margin: 0;
  font-family: 'Pretendard', serif;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.4rem;
  letter-spacing: -0.042rem;
}

/* 과거 날짜 */
.react-datepicker__day--disabled:hover {
  background-color: #fff;
  color: #cccccc;

  > span {
    color: #cecece;
  }

  :hover {
    background-color: #fff;
  }
}

.react-datepicker__day--outside-month {
  color: #cccccc;
}

.button .span {
  font-size: 1.3rem;
  font-weight: 800;
  color: #000;
  line-height: 1.6rem;
  font-family: 'Pretendard', serif;
}

.button:hover {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.18);
}
